* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  background-color: #353353;
  color: #fff;
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

/* #root {
  width: 100%;
  height: 100%;
} */
